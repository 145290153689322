<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.747" x2="0.346" y2="1">
     <stop offset="0" stop-color="#0549b7"/>
     <stop offset="1" stop-color="#343131"/>
    </linearGradient>
   </defs>
   <g id="Behance" transform="translate(-370 -208)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(370 208)" width="60"/>
    <path d="M14.777,15.607a4.834,4.834,0,0,0,3.083-4.713c0-4.5-3.35-5.592-7.217-5.592H0V27.875H10.943c4.1,0,7.955-1.968,7.955-6.554a5.439,5.439,0,0,0-4.121-5.713ZM4.962,9.155H9.618c1.79,0,3.4.5,3.4,2.58,0,1.917-1.255,2.688-3.025,2.688H4.962ZM10.268,24.04H4.962V17.824h5.408c2.185,0,3.567.911,3.567,3.223C13.936,23.327,12.287,24.04,10.268,24.04ZM33.1,8.709H23.949V6.486H33.1Zm3.586,11.229c0-4.834-2.828-8.866-7.955-8.866-4.981,0-8.363,3.745-8.363,8.65,0,5.089,3.2,8.58,8.363,8.58,3.9,0,6.433-1.758,7.65-5.5H32.42c-.427,1.395-2.185,2.134-3.548,2.134-2.631,0-4.013-1.541-4.013-4.159H36.65C36.669,20.512,36.688,20.225,36.688,19.939ZM24.866,17.951a3.508,3.508,0,0,1,3.726-3.49c2.255,0,3.389,1.325,3.58,3.49Z" data-name="Icon awesome-behance" id="Icon_awesome-behance" style="fill: url(#linear-gradient);" transform="translate(382 221.698)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>